.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-container {
  @extend .container;
  @include pc {
    width: 78.6rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
  }
}
