.resume-card-wrapper {
  display: flex;
  width: auto;
  height: auto;
  position: relative;
}

.resume-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
  border-radius: 1rem;
  @include pc {
    width: 33.8rem;
    padding: 2rem;
  }
  @include mobile {
    width: calc(100% - 3rem);
    padding: 1.5rem;
  }
  height: 19.8rem;
}

.resume-long-card {
  @extend .resume-card;
  @include pc {
    width: 74.6rem;
  }
  height: auto;
}

.resume-long-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.resume-long-sub {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  @include pc {
    margin-left: 5rem;
  }
}

.resume-card-profile {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  @include pc {
    margin-right: 1rem;
  }
  @include mobile {
    margin-right: 0.5rem;
  }
  border-radius: 100rem;
  flex-shrink: 0;
}

.resume-card-menu-icon {
  width: 2.6rem;
  position: absolute;
  top: 0rem;
  right: -1rem;
  padding: 2rem;
  cursor: pointer;
}

.resume-card-contents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resume-card-summary {
  font-weight: 500;
  color: $dark-blue;
  @include pc {
    font-size: 2rem;
  }
  @include mobile {
    font-size: 1.8rem;
  }
}

.resume-card-subinfo {
  display: flex;
  color: $dark-gray;
  @include pc {
    font-size: 2rem;
    gap: 0.5rem;
  }
  @include mobile {
    font-size: 1.8rem;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.resume-title-container {
  display: flex;
  flex-shrink: 0;
  @include pc {
    gap: 1rem;
  }
  @include mobile {
    gap: 0.5rem;
  }
  margin-bottom: 0.5rem;
}

.resume-card-title {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: nowrap;
  font-weight: 700;
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2.2rem;
  }
}

.resume-card-job {
  font-weight: 500;
  @include pc {
    font-size: 2.2rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.resume-card-date {
  font-size: 2rem;
  color: $dark-gray;
}

.resume-card-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  .text {
    flex-wrap: nowrap;
    font-size: 1.6rem;
    color: $dark-gray;
    margin-top: 0.5rem;
  }
}

.resume-comment-container {
  @extend .resume-card-contents;
  gap: 0.5rem;
  @include comment-pc {
    margin: 2rem 0rem 0 5rem;
  }
  @include comment-mobile {
    margin: 2rem 0rem 0 0rem;
  }
}

.resume-comment-txt {
  white-space: pre-line;
  font-weight: 500;
  @include pc {
    font-size: 1.8rem;
  }
  @include mobile {
    font-size: 1.6rem;
  }
  color: $dark-gray;
  span {
    color: $dark-green;
  }
}

.resume-long-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.resume-detail-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  @include pc {
    margin-bottom: 4rem;
  }
  @include mobile {
    margin-bottom: 2rem;
  }
}
.resume-detail-info {
  display: flex;
  width: 100%;
  .name {
    @extend .resume-title-container;
    margin: 0;
  }
  .profile {
    @extend .resume-card-profile;
    width: 6rem;
    height: 6rem;
    border-radius: 100rem;
  }
}
