@import './variables';

/* button and content colors */
.dark-green {
  background-color: $dark-green;
  color: $white;
}

.green {
  background-color: $green;
  color: $black;
}

.white {
  background-color: $white;
  color: $dark-green;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
}

.white-blue {
  background-color: $white;
  color: $dark-blue;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
}

.dark-blue {
  background-color: $dark-blue;
  color: $white;
}

.kakao-color {
  background-color: $kakao-bg;
  color: $kakao-label;
}

.disabled {
  background-color: $light-gray;
  color: $white;
}

/* mini button colors */
.light-gray {
  background-color: $white;
  color: $black;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
}

.red {
  background-color: $white;
  color: $red;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
}

/* tag colors */
.green-tag {
  color: $dark-green;
  background-color: $pale-green;
}

.blue-tag {
  color: $dark-blue;
  background-color: $pale-blue;
}

.red-tag {
  color: $red;
  background-color: $pale-red;
}

.gray-tag {
  color: $dark-gray;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
}

.skill-tag {
  background-color: $pale-gray;
  color: $dark-gray;
}

.gray {
  background-color: $white;
  box-shadow: inset 0 0 0 0.1rem $gray;
}

.star-tag {
  background-color: $star-bg;
  color: $orange;
}
