.swiper {
  @include mobile {
    height: 30rem;
  }
  width: 100%;
  height: 50vh;
  position: relative;
  z-index: 0;
}

.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: hidden;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  width: 2rem;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}

.banner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.banner-main {
  height: 100%;
}

.banner-search {
  @include pc {
    height: 40rem;
    img {
      height: 40rem;
    }
  }
  @include mobile {
    height: 20rem;
    img {
      height: 20rem;
    }
  }
}

.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $black-bg;
}

.banner-txt {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: 600;
  color: $white;
  text-align: center;
  @include mobile {
    white-space: pre-line;
  }
  .title {
    margin: 0 0 4vh 0;
    @include pc {
      font-size: 4rem;
    }
    @include mobile {
      font-size: 3.2rem;
    }
  }
  .content {
    font-weight: 500;
    margin: 0 0 4vh 0;
    @include pc {
      font-size: 3.6rem;
    }
    @include mobile {
      font-size: 2.4rem;
    }
  }
  .mini {
    font-weight: 400;
    margin: 2vh 0 0 0;
    @include pc {
      font-size: 2.4rem;
    }
    @include mobile {
      font-size: 1.6rem;
    }
  }
}
