.record-wrapper {
  display: flex;
  flex-direction: column;
}

.record-div {
  @extend .record-wrapper;
  gap: 2rem;
}

.record-div-mini {
  @extend .record-div;
  margin-bottom: 2rem;
}

.record-container {
  display: flex;
  justify-content: space-between;
}

.record-mini {
  width: calc(100%-2rem);
  margin-left: 2rem;
}

.date-font {
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.record-date {
  @extend .date-font;
  display: flex;
  color: $dark-gray;
  gap: 0.5rem;
  @include pc {
    line-height: 3rem;
  }
  @include mobile {
    line-height: 2rem;
  }
  input {
    &:focus {
      box-shadow: none;
    }
    @extend .date-font;
    @include pc {
      width: 9rem;
      height: 3rem;
    }
    @include mobile {
      width: 7.5rem;
      height: 2rem;
    }
  }
  input::placeholder {
    @extend .date-font;
    color: $dark-gray;
  }
}

.suggestion-date {
  display: flex;
  gap: 3rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.date-label {
  color: $black;
  @include pc {
    font-size: 2.8rem;
  }
  @include mobile {
    font-size: 2.4rem;
  }
}

.record-delete {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.show-record-wrapper {
  @extend .record-wrapper;
  gap: 0.5rem;

  .first-name {
    font-weight: 500;
    @include pc {
      font-size: 2.8rem;
    }
    @include mobile {
      font-size: 2.2rem;
    }
  }

  .second-name {
    @include pc {
      font-size: 2.4rem;
    }
    @include mobile {
      font-size: 2rem;
    }
  }

  .duration {
    color: $dark-gray;
    @include pc {
      font-size: 2rem;
    }
    @include mobile {
      font-size: 1.6rem;
    }
  }
}

.show-record-mini-wrapper {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  .detail-name {
    font-weight: 500;
    @include pc {
      font-size: 2.4rem;
    }
    @include mobile {
      font-size: 2rem;
    }
  }
  .detail-contents {
    @include pc {
      font-size: 2rem;
    }
    @include mobile {
      font-size: 1.8rem;
    }
  }
  .detail-duration {
    color: $dark-gray;
    @include pc {
      font-size: 1.8rem;
    }
    @include mobile {
      font-size: 1.6rem;
    }
  }
}

.dot {
  border-radius: 100rem;
  background-color: $black;
  display: flex;
  flex-shrink: 0;
  @include pc {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 1rem;
  }
  @include mobile {
    width: 0.6rem;
    height: 0.6rem;
    margin-top: 0.8rem;
  }
}
