@import '../abstracts/mixin';

.subtitle {
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include pc {
    font-size: 3.6rem;
    margin-bottom: 2rem;
    gap: 0.5rem;
  }
  @include mobile {
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
    gap: 0.25rem;
  }
  div {
    width: 100%;
    height: 0.2rem;
  }
}
