@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css');

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

ul,
ol {
  padding-left: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-family: inherit;
}

input,
select,
textarea {
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 0.1rem $dark-green;
  }
}
