@import '../abstracts/variables';

.select-container {
  display: flex;
  width: 100%;
  @include pc {
    gap: 3rem;
  }
  @include mobile {
    gap: 2rem;
  }
}
.select {
  @include pc {
    width: 78.6rem;
    height: 6.4rem;
  }
  @include mobile {
    width: 100%;
    height: 5rem;
  }
  border-radius: 1rem;
  color: $dark-gray;
}

.select-mini {
  @extend .select;
  @include pc {
    width: 37.8rem;
  }
  @include mobile {
    width: 100%;
  }
}

.select-long {
  @extend .select;
  width: 100%;
}

.multiple {
  width: 100%;
}

.select-tag {
  background-color: $dark-green;
  color: $white;
}

.slider-title {
  @include pc {
    margin: 2rem 0;
    font-size: 2.8rem;
  }
  @include mobile {
    margin: 1rem 0;
    font-size: 2.2rem;
  }
  font-weight: 500;
}

.ant-select-selector,
.blue-select-selector {
  box-shadow: none !important;
}

.ant-select-selection-item,
.ant-select-selection-placeholder,
.blue-select-selection-item,
.blue-select-selection-placeholder {
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.ant-select-selection-placeholder,
.blue-select-selection-placeholder {
  color: $dark-gray !important;
}

.ant-select-dropdown,
.blue-select-dropdown {
  z-index: 1090;
}

.blue-select-item-option-selected {
  background-color: $light-blue !important;
}
.blue-select-item-option-state {
  color: $dark-blue !important;
}
.blue-select-selection-placeholder {
  color: $dark-gray !important;
}
.blue-select-focused {
  .blue-select-selector {
    border-color: $dark-blue !important;
  }
}

.blue-tooltip-inner,
.green-tooltip-inner {
  color: $white !important;
  padding: 3px 8px !important;
  font-size: 1.8rem !important;
  text-align: center !important;
}
.green-tooltip-inner {
  background-color: rgba(1, 186, 114, 0.7) !important;
}
.green-tooltip-arrow::before {
  background-color: rgba(1, 186, 114, 0.6) !important;
}

.blue-tooltip-inner {
  background-color: rgba(65, 192, 242, 0.7) !important;
}
.blue-tooltip-arrow::before {
  background-color: rgba(65, 192, 242, 0.6) !important;
}

.blue-slider {
  .ant-slider-handle:hover::after {
    box-shadow: 0 0 0 0.4rem $dark-blue !important;
  }
  .ant-slider-handle::after {
    box-shadow: 0 0 0 0.2rem $dark-blue !important;
  }
  .ant-slider-handle:active::after {
    box-shadow: 0 0 0 0.4rem $dark-blue !important;
  }
  .ant-slider-dot-active {
    border-color: $dark-blue !important;
  }
  .ant-slider-track {
    background-color: $dark-blue !important;
  }
}

.ant-slider-mark-text {
  @include pc {
    font-size: 1.8rem;
  }
  @include mobile {
    font-size: 1.4rem;
  }
}

.multiple {
  .ant-select-selection-search,
  .blue-select-selection-search {
    @include pc {
      width: 78.6rem;
      height: 6.4rem;
    }
    @include mobile {
      width: 100%;
      height: 5rem;
    }
  }
  .ant-select-selection-search-input,
  .blue-select-selection-search-input {
    @include pc {
      font-size: 2.4rem;
    }
    @include mobile {
      font-size: 2rem;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.ant-tag {
  display: flex;
  align-items: center;
  @include pc {
    font-size: 2.4rem !important;
    height: 5rem !important;
    margin: 0.5rem;
  }
  @include mobile {
    font-size: 2rem !important;
    height: 4rem !important;
    margin: 0.5rem;
  }
}

.filter-select {
  .blue-select-selection-item {
    @include pc {
      font-size: 2.2rem !important;
    }
    @include mobile {
      font-size: 1.8rem !important;
    }
  }
}

.ant-spin {
  color: $dark-blue;
}

.ant-spin-dot-item {
  background-color: $dark-blue !important;
}
