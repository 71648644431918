@import '../abstracts/mixin';
@import '../abstracts/variables';

.long-btn {
  @include btn;
  @include pc {
    width: 58.2rem;
    height: 6rem;
    font-size: 2.8rem;
  }
  @include mobile {
    width: 100%;
    height: 5rem;
    font-size: 2rem;
  }
}

.short-btn {
  @include btn {
    padding: 2rem;
    width: max-content;
  }
  @include pc {
    height: 6rem;
    font-size: 2.4rem;
    margin-top: 4.8rem;
    margin-left: 2rem;
  }
  @include mobile {
    height: 5rem;
    font-size: 2rem;
    margin-top: 3.9rem;
    margin-left: 0.5rem;
  }
}

.row-btn {
  @include pc {
    margin-top: 0;
  }
  @include mobile {
    margin-top: 3.4rem;
  }
}

.mini-btn {
  @include btn {
    font-weight: 400;
  }
  @include pc {
    width: 10rem;
    height: 4rem;
    font-size: 2rem;
  }
}

.kakao-btn {
  @include btn;
  position: relative;
  img {
    position: absolute;
    opacity: 90%;
  }
  @include pc {
    width: 58.2rem;
    height: 6rem;
    font-size: 2.8rem;
    img {
      width: 3.5rem;
      left: 2.8rem;
    }
  }
  @include mobile {
    width: 100%;
    height: 5rem;
    font-size: 2rem;
    img {
      width: 2.2rem;
      left: 1.6rem;
    }
  }
}

.abreast-btn {
  @include btn;
  @include pc {
    width: 37.8rem;
    height: 6rem;
    font-size: 2.8rem;
  }
  @include mobile {
    display: flex;
    width: calc(50% - 1rem);
    height: 5rem;
    font-size: 2rem;
  }
}

.modal-btn {
  @include btn;
  @include pc {
    width: 17.4rem;
    height: 6rem;
    font-size: 2.8rem;
  }
  @include mobile {
    width: 33vw;
    height: 5rem;
    font-size: 2rem;
  }
}

/* buttons container style for InfoEdit, CompanyForm, SeniorForm, FindPages */
.btns-div {
  @include pc {
    display: flex;
    justify-content: space-between;
    width: 78.6rem;
  }
  @include mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

/* NoticeItem, ManagementItem button style */
.inner-btn {
  @include btn;
  width: 100%;
  &.light-gray {
    color: $gray;
    cursor: default;
  }
  @include pc {
    height: 6rem;
    font-size: 2.4rem;
  }
  @include mobile {
    height: 5rem;
    font-size: 2rem;
  }
}

/* The style of a button with width up to breakpoint */
.longer-btn {
  @include btn;
  @include pc {
    width: 78.6rem;
    height: 6rem;
    font-size: 2.8rem;
  }
  @include mobile {
    width: 100%;
    height: 5rem;
    font-size: 2rem;
  }
}

.mypage-btn {
  @include btn {
    padding: 2rem;
    width: max-content;
  }
  @include pc {
    height: 5rem;
    font-size: 2.4rem;
  }
  @include mobile {
    height: 4rem;
    font-size: 2rem;
  }
}

.search-btn {
  @extend .mypage-btn;
  @include pc {
    height: 6rem;
    width: 8rem;
  }
  @include mobile {
    height: 5rem;
    width: 6rem;
  }
}
