@import '../abstracts/mixin';

/* default info */
.info-div {
  @include pc {
    display: flex;
    flex-direction: column;
  }
}

/* defaultResume & Suggestion */
.mypage-semi-div {
  @include pc {
    display: flex;
    flex-direction: column;
  }
}

.mypage-semi-outline {
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  @include pc {
    width: 33rem;
    height: 19rem;
    padding: 2.4rem;
    gap: 2.4rem;
    font-size: 2.4rem;
  }
  @include mobile {
    &.mypage-semi-suggest {
      box-shadow: none;
    }
    width: 100%;
    height: 23.8rem;
    gap: 2rem;
    font-size: 2rem;
  }
}

.mypage-semi-box {
  width: 100%;
  display: flex;
  @include pc {
    justify-content: space-between;
  }
  @include mobile {
    flex-direction: column;
    p:last-child {
      margin-top: 0.7rem;
    }
  }
  p:first-child {
    font-weight: 600;
  }
}

.mypage-semi-notice {
  width: 100%;
  height: 100%;
  color: $gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* common style */
.mypage-div {
  display: flex;
  @include pc {
    justify-content: center;
    margin-bottom: 8rem;
    .mypage-small-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 3rem;
    }
  }
  @include mobile {
    flex-direction: column;
    align-items: center;
    .mypage-mobile-div {
      width: calc(100% - 4.8rem);
    }
  }
}

.mypage-title {
  cursor: default;
  @include pc {
    font-size: 3.6rem;
    font-weight: 500;
  }
  @include mobile {
    font-size: 2.2rem;
    font-weight: 600;
  }
}

.mypage-flexdiv {
  * {
    margin: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pc {
    width: 37.8rem;
    height: 7rem;
  }
  @include mobile {
    width: 100%;
    margin-top: 2.4rem;
    margin-bottom: 1rem;
  }
}

/* profile style */
.mypage-profile-div {
  @include pc {
    display: flex;
    flex-direction: column;
  }
}

.profile-title {
  @include mobile {
    display: none;
  }
}
