@import '../abstracts/mixin';

.profile-div {
  display: flex;
  align-items: center;
  border-radius: 1rem;
  cursor: default;
  @include pc {
    width: 37.8rem;
    height: 14rem;
  }
  @include mobile {
    width: 100%;
    height: 12rem;
    margin-bottom: 2.4rem;
  }
}

.profile-user-box {
  display: flex;
  flex-direction: column;
  @include pc {
    margin-left: 4rem;
  }
  @include mobile {
    margin-left: 2.4rem;
  }
}

.profile-username {
  display: flex;
  p:first-child {
    font-weight: 500;
  }
  @include pc {
    margin-bottom: 1rem;
    p {
      font-size: 3.2rem;
    }
  }
  @include mobile {
    margin-bottom: 1.6rem;
    p {
      font-size: 2.4rem;
    }
  }
}
