@import '../abstracts/colors';
@import '../abstracts/mixin';

.infoedit-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoedit-div {
  @include mobile {
    width: calc(100% - 4.8rem);
  }
  .inputs-div {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    @include pc {
      padding: 4rem;
      padding-bottom: 0;
      margin-bottom: 6rem;
      box-shadow: 0 0 0 0.1rem $light-gray;
    }
  }
}
