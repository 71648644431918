@import '../abstracts/mixin';
@import '../abstracts/variables';

.modal-bg-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: $black-bg;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-div {
  position: fixed;
  background-color: $white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include pc {
    width: 37.8rem;
    padding: 4rem 10rem;
  }
  @include mobile {
    width: 70vw;
    padding: 3rem;
  }
}

.modal-title {
  font-weight: 600;
  @include pc {
    font-size: 3.6rem;
  }
  @include mobile {
    font-size: 2.8rem;
  }
}

.modal-text {
  text-align: center;
  @include pc {
    font-size: 2.4rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    &.withdrawal {
      margin-top: 6rem;
      margin-bottom: 5rem;
    }
  }
  @include mobile {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    &.withdrawal {
      margin-top: 4rem;
      margin-bottom: 3rem;
    }
  }
}

.modal-btn-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
