@import '../abstracts/colors';
@import '../abstracts/mixin';

.usertag {
  width: max-content;
  border-radius: 0.5rem;
  padding: 0.4rem 0.6rem;
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.resume-tag {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 0.5rem;
  font-size: 1.8rem;
  padding: 0.4rem 0.6rem;
  &.gray-tag,
  &.skill-tag {
    white-space: nowrap;
  }
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.3rem;
  }
}
