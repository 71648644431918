/* colors */
$black: #333333;
$dark-gray: #767676;
$gray: #999999;
$light-gray: #d8d8d8;
$pale-gray: #f5f5f5;
$dark-green: #01ba72;
$green: #c8f785;
$light-green: #e2ffb9;
$pale-green: #f0ffda;
$white: #ffffff;
$dark-blue: #41c0f2;
$blue: #70cff5;
$light-blue: #cfeffc;
$pale-blue: #edfaff;
$pale-red: #ffefef;
$red: #ff0000;
$star-bg: #fff5d5;
$orange: #ff8a00;
$kakao-bg: #fee500;
$kakao-label: #191919;
$black-bg: rgba(0, 0, 0, 0.5);

/* breakpoint */
$mobile: 785px + 17px;
$main-breakpoint: 1193px + 17px;
$comment-breakpoint: 460px + 17px;
