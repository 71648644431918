@import '../abstracts/mixin';

.picture-div {
  position: relative;
  margin-left: 2.4rem;
  display: flex;
  align-items: center;
  @include pc {
    width: 11rem;
    height: 11rem;
  }
  @include mobile {
    width: 8.5rem;
    height: 8.5rem;
  }
}

.picture {
  border-radius: 100%;
  object-fit: cover;
  @include pc {
    width: 10rem;
    height: 10rem;
  }
  @include mobile {
    width: 8rem;
    height: 8rem;
  }
}

.picture-icon {
  position: absolute;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0rem;
  right: 0rem;
  cursor: pointer;
  @include pc {
    width: 4.5rem;
    height: 4.5rem;
  }
  @include mobile {
    width: 3.6rem;
    height: 3.6rem;
    img {
      width: 2rem;
      height: 2rem;
    }
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
}
