@import '../abstracts/mixin';
@import '../abstracts/variables';

/* sign in form styles */
.signin-form {
  display: flex;
  flex-direction: column;

  @include mobile {
    width: calc(100% - 4.8rem);
  }

  .signin-btn-wrapper {
    margin-top: 1rem;
    div:last-child {
      margin-top: 2rem;
    }
  }
}

/* alert text style */
.signin-alert {
  height: 3rem;
  display: flex;
  align-items: center;
  color: $red;
  @include pc {
    font-size: 1.8rem;
  }
  @include mobile {
    font-size: 1.6rem;
  }
}

/* 아이디 찾기, 비밀번호 찾기, 회원가입 button styles */
.signin-mini-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 8rem;

  button {
    color: $black;
    cursor: pointer;
    @include pc {
      font-size: 2rem;
    }
    @include mobile {
      font-size: 1.8rem;
    }
  }
  .signup {
    color: $dark-green;
  }

  .line {
    border: 0.05rem solid $light-gray;
    margin: 0 1.5rem;
    height: 2.2rem;
  }
}
