@import '../abstracts/colors';
@import '../abstracts/mixin';
@import '../abstracts/variables';

/* SuggetionForm style */
/* style for price suggestion */
.suggest-form-price-div {
  width: 100%;
  p:first-child {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.suggest-notice-p {
  color: $dark-gray;
  @include pc {
    font-size: 2rem;
  }
  @include mobile {
    font-size: 1.6rem;
  }
}

.suggest-form-price-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 1rem;
  &:focus-within {
    box-shadow: inset 0 0 0 0.1rem $dark-blue;
  }
  input:focus {
    box-shadow: none;
  }
  input {
    width: 100%;
    height: 100%;
  }
  p {
    white-space: nowrap;
    padding-right: 2rem;
  }
  @include pc {
    width: 37.8rem;
    height: 6rem;
    margin: 1rem 0;
    input {
      padding: 0 2rem;
      font-size: 2.4rem;
    }
  }
  @include mobile {
    width: 100%;
    height: 5rem;
    margin: 0.5rem 0;
    input {
      padding: 0 1rem;
      font-size: 2rem;
    }
  }
}

/* style for SuggestionForm job description div, CancelForm textarea div */
.suggest-form-textarea-div {
  textarea {
    &:focus {
      box-shadow: inset 0 0 0 0.1rem $dark-blue;
    }
  }
  &.cancel-form {
    textarea {
      margin-bottom: 2rem;
      @include mobile {
        height: 20rem;
      }
    }
  }
  @include pc {
    margin-bottom: 8rem;
  }
  @include mobile {
    margin-bottom: 6rem;
  }
}

/* SuggestionManagement style */
.suggest-manage-div {
  @include pc {
    width: 78.6rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
  }
  .search-input {
    width: 100%;
  }
}

/* ManagementSelection style */
.suggest-manage-select-wrapper {
  border-radius: 1rem;
  width: 100%;
  @include pc {
    height: 6.4rem;
    font-size: 2.4rem;
    margin: 2rem 0;
  }
  @include mobile {
    height: 5rem;
    font-size: 2rem;
    margin: 1rem 0;
  }
}

/* ManagementItem style */
.suggest-manage-profile-div {
  cursor: pointer;
}

.suggest-manage-btn-div {
  @include pc {
    margin-top: 2rem;
  }
  @include mobile {
    margin-top: 2rem;
  }
}

/* Payment style */
.suggest-pay-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include pc {
    gap: 8rem;
  }
  @include mobile {
    gap: 2rem;
  }
  .resume-tag {
    @include pc {
      font-size: 2.2rem;
    }
    @include mobile {
      font-size: 1.8rem;
    }
  }
}

.suggest-pay-text-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.suggest-pay-p {
  &.medium {
    font-weight: 500;
  }
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.suggest-pay-radio-div {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    align-items: center;
    @include pc {
      margin: 1rem 0;
      font-size: 2rem;
    }
    @include mobile {
      margin: 0.5rem 0;
      font-size: 1.8rem;
    }
  }
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.1rem solid $gray;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    @include pc {
      width: 2rem;
      height: 2rem;
      margin: auto 0;
      margin-right: 1rem;
    }
    @include mobile {
      width: 1.8rem;
      height: 1.8rem;
      margin: auto 0;
      margin-right: 0.5rem;
    }
  }
  input[type='radio']:checked {
    background-color: $dark-blue;
    box-shadow: 0 0 0 0.1rem $dark-blue;
    @include pc {
      border: 0.4rem solid $white;
    }
    @include mobile {
      border: 0.3rem solid $white;
    }
  }
}

/* SuggestionPaymentComplete style */
.suggest-pay-complete-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  @include pc {
    margin: 8rem 0;
  }
  @include mobile {
    margin: 6rem 0;
  }
  :first-child {
    font-weight: 500;
    @include pc {
      font-size: 3.2rem;
    }
    @include mobile {
      font-size: 2.8rem;
    }
  }
  :last-child {
    @include pc {
      font-size: 2.8rem;
    }
    @include mobile {
      font-size: 2.4rem;
    }
  }
}

/* SuggestionDetail style */
/* company profile style */
.suggest-detail-profile {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  @include pc {
    font-size: 3.2rem;
    margin-bottom: 5rem;
  }
  @include mobile {
    font-size: 2.8rem;
    margin-bottom: 3rem;
  }
}

.suggest-detail-profile-img {
  border-radius: 100%;
  overflow: hidden;
  border: 0.1rem solid $light-gray;
  margin-right: 2rem;
  @include pc {
    width: 7.5rem;
    height: 7.5rem;
  }
  @include mobile {
    width: 6.5rem;
    height: 6.5rem;
  }
  img {
    width: 100%;
  }
}

/* company suggestion detail style */
.suggest-detail-content-div {
  width: 100%;
}

.suggest-detail-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  @include pc {
    font-size: 2.4rem;
    margin-bottom: 5rem;
    margin-top: 0.5rem;
  }
  @include mobile {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}

.suggest-detail-jd {
  border-radius: 1rem;
  @include pc {
    padding: 2rem;
  }
  @include mobile {
    padding: 1rem;
    margin-bottom: 4rem;
  }
}
