.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @include mobile {
    height: 10rem;
    margin-top: 4rem;
  }
  @include pc {
    height: 15rem;
    margin-top: 8rem;
  }
  background-color: $pale-gray;
  position: relative;
  transform: translateY(0%);
}

.footer_txt {
  width: max-content;
  @include mobile {
    height: 1.6rem;
    font-size: 0.9rem;
    margin-left: 2.4rem;
  }
  @include pc {
    height: 2.4rem;
    font-size: 1.6rem;
    margin-left: 7.6rem;
  }
}

.wrapper {
  height: auto;
  @include mobile {
    min-height: calc(100vh - 14rem);
  }
  @include pc {
    min-height: calc(100vh - 23rem);
  }
}
