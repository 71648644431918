@import '../abstracts/colors';
@import '../abstracts/mixin';

.contact-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include pc {
    margin-bottom: 4rem;
  }
  @include mobile {
    margin-bottom: 2rem;
  }
}

.contact-info-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  div {
    display: flex;
    align-items: center;
    @include pc {
      font-size: 2.4rem;
    }
    @include mobile {
      font-size: 2rem;
    }
    img {
      margin-right: 1rem;
      @include mobile {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}
