@import '../abstracts/mixin';
@import '../abstracts/variables';

/* tab style */
.tab-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tab-wrapper {
    @include pc {
      width: 64.2rem;
      height: 6.4rem;
      font-size: 3.6rem;
    }
    @include mobile {
      width: 100%;
      height: 4.5rem;
      font-size: 2.4rem;
    }
    display: flex;
    margin-bottom: 3rem;
    cursor: pointer;

    div {
      display: flex;
      justify-content: center;
      width: 50%;
      height: 100%;
      font-weight: 600;
      border-bottom: 0.1rem solid $light-gray;
    }
    .active {
      border-bottom: 0.3rem solid $dark-green;
      color: $dark-green;
    }
  }
}
