.resume-detail-tab-div {
  @extend .resume-tab-div;
  .tab-wrapper {
    margin-bottom: 4rem;
    .active {
      border-bottom: 0.3rem solid $dark-blue;
      color: $dark-blue;
    }
  }
}

.senior-resume-contents {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.senior-portfolio {
  @extend .gray-tag;
  &:hover {
    color: $dark-green;
    box-shadow: inset 0 0 0 0.1rem $dark-green;
  }
}

.senior-intro {
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
}

.senior-info {
  width: 100%;
  margin-top: -2rem;
}
