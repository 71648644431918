@import '../abstracts/mixin';
@import '../abstracts/variables';

.row-input-div {
  @include input-div {
    @include pc {
      width: 70.6rem;
    }
  }
}

.phone-input-div {
  @include input-div {
    @include pc {
      width: 66.6rem;
    }
  }
}

.cert-input-div {
  @include input-div {
    @include pc {
      width: 52.2rem;
    }
    @include mobile {
      width: 40vw;
      button {
        margin-top: 1rem;
      }
    }
  }
}

.input-div {
  @include input-div {
    @include pc {
      width: 78.6rem;
    }
  }
}

.input-signin-div {
  display: flex;
  flex-direction: column;

  @include input-div {
    @include pc {
      width: 58.2rem;
    }
  }

  .input-label-box p {
    @include pc {
      font-size: 3rem;
    }
  }

  .alert-text {
    min-height: 2rem;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* activated phone number edit form style  */
.phone-input {
  @include pc {
    flex-direction: row;
    width: 66.6rem;
    justify-content: space-between;
    &.phone-num .alert-text {
      min-height: 2rem;
    }
  }
  @include mobile {
    &.phone-num .alert-text {
      display: none;
    }
  }
  .input-label-box {
    width: 16.4rem;
    p {
      @include pc {
        width: 16.4rem;
        height: 6rem;
        font-size: 2.4rem;
      }
      @include mobile {
        font-size: 2rem;
      }
    }
  }
  .input {
    @include pc {
      margin: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.row {
  @include pc {
    flex-direction: row;
    width: 70.6rem;
    justify-content: space-between;
  }
  .input-label-box {
    width: 16.4rem;
    p {
      @include pc {
        width: 16.4rem;
        height: 6rem;
        font-size: 2.4rem;
      }
      @include mobile {
        font-size: 2rem;
      }
    }
  }
  .input {
    @include pc {
      margin: 0;
      width: 100%;
      box-sizing: border-box;
    }
  }
}

.input-label-box {
  display: flex;
  p {
    display: flex;
    align-items: center;
    font-weight: 600;
    width: max-content;
    @include pc {
      font-size: 3.2rem;
      margin-right: 1rem;
    }
    @include mobile {
      font-size: 2.4rem;
      margin-right: 0.5rem;
    }
  }
  .required {
    color: $red;
  }
}

.input-box {
  width: 100%;
}

.input {
  margin-top: 1rem;
  box-shadow: inset 0 0 0 0.1rem $light-gray;
  border-radius: 1rem;
  width: 100%;
  box-sizing: border-box;
  @include pc {
    @include pc-input;
  }
  @include mobile {
    @include mobile-input;
  }
}

.alert-text {
  display: flex;
  align-items: center;
  word-wrap: break-word;
  @include pc {
    min-height: 4rem;
    font-size: 2rem;
  }
  @include mobile {
    min-height: 3rem;
    font-size: 1.8rem;
  }
}

.input-red {
  color: $red;
  input {
    box-shadow: inset 0 0 0 0.1rem $red;
  }
}
