@import '../abstracts/mixin';
@import '../abstracts/variables';

.small-arrow {
  @include arrow {
    width: 1.2rem;
    height: 1.2rem;
  }
}

.big-arrow {
  @include arrow {
    @include pc {
      width: 1.7rem;
      height: 1.7rem;
      &::after {
        border-top: 0.3rem solid $white;
        border-right: 0.3rem solid $white;
      }
    }
    @include mobile {
      margin-left: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.black-arrow {
  @include arrow {
    width: 1.2rem;
    height: 1.2rem;
    &::after {
      border-top: 0.2rem solid $black;
      border-right: 0.2rem solid $black;
    }
  }
}

.up-arrow {
  @include arrow {
    @include pc {
      width: 1.2rem;
      height: 1.2rem;
      &.white-arrow::after {
        border-left: 0.3rem solid $white;
        border-top: 0.3rem solid $white;
      }
      &::after {
        top: 25%;
        border: none;
        border-left: 0.3rem solid $gray;
        border-top: 0.3rem solid $gray;
      }
    }
    @include mobile {
      margin-left: 1.5rem;
      width: 0.8rem;
      height: 0.8rem;
      &.white-arrow::after {
        border-left: 0.2rem solid $white;
        border-top: 0.2rem solid $white;
      }
      &::after {
        top: 25%;
        border: none;
        border-left: 0.2rem solid $gray;
        border-top: 0.2rem solid $gray;
      }
    }
  }
}

.down-arrow {
  @include arrow {
    @include pc {
      width: 1.2rem;
      height: 1.2rem;
      &.white-arrow::after {
        border-right: 0.3rem solid $white;
        border-bottom: 0.3rem solid $white;
      }
      &::after {
        top: -25%;
        border: none;
        border-right: 0.3rem solid $gray;
        border-bottom: 0.3rem solid $gray;
      }
    }
    @include mobile {
      margin-left: 1.5rem;
      width: 0.8rem;
      height: 0.8rem;
      &.white-arrow::after {
        border-right: 0.2rem solid $white;
        border-bottom: 0.2rem solid $white;
      }
      &::after {
        top: -25%;
        border: none;
        border-right: 0.2rem solid $gray;
        border-bottom: 0.2rem solid $gray;
      }
    }
  }
}

.check-arrow {
  @include arrow {
    @include pc {
      margin-left: 1.7rem;
      width: 1.2rem;
      height: 3rem;
      &::after {
        top: -20%;
        border: none;
        border-right: 0.3rem solid $black;
        border-bottom: 0.3rem solid $black;
      }
    }
    @include mobile {
      margin-left: 1.06em;
      width: 0.6rem;
      height: 1.9rem;
      &::after {
        top: -20%;
        border: none;
        border-right: 0.3rem solid $black;
        border-bottom: 0.3rem solid $black;
      }
    }
  }
}
