@import './variables';

/* responsive web setting */
@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin pc {
  @media (min-width: ($mobile + 1)) {
    @content;
  }
}

/* mainpage content responsive web setting */
@mixin main-mobile {
  @media (max-width: $main-breakpoint) and (min-width: $mobile) {
    @content;
  }
}

@mixin main-pc {
  @media (min-width: ($main-breakpoint + 1)) {
    @content;
  }
}

@mixin comment-pc {
  @media (min-width: ($comment-breakpoint + 1)) {
    @content;
  }
}

@mixin comment-mobile {
  @media (max-width: $comment-breakpoint) {
    @content;
  }
}

/* button common css */
@mixin btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  @content;
}

/* input tag common css */
@mixin pc-input {
  padding: 0 2rem;
  height: 6rem;
  font-size: 2.4rem;
}

@mixin mobile-input {
  padding: 0 1rem;
  height: 5rem;
  font-size: 2rem;
}

/* div css which contains Input component(and Btn) */
@mixin input-div {
  display: flex;
  justify-content: space-between;
  @include mobile {
    width: 100%;
  }
  @content;
}

/* arrow style */
@mixin arrow {
  margin-left: 2rem;

  position: relative;
  display: flex;

  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -50%;
    top: 0;
    content: '';
    border-top: 0.2rem solid $white;
    border-right: 0.2rem solid $white;
    transform: rotate(45deg);
  }
  @content;
}
