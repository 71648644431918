@import '../abstracts/colors';
@import '../abstracts/mixin';

/* tab style */
.about-tab-div {
  display: flex;
  justify-content: center;
  div {
    display: flex;
    justify-content: center;
    @include mobile {
      width: calc(100% - 4.8rem);
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    &.senior-active {
      background-color: $dark-green;
      color: $white;
    }
    &.company-active {
      background-color: $dark-blue;
      color: $white;
    }
    &.senior-default {
      color: $dark-green;
    }
    &.company-default {
      color: $dark-blue;
    }
    @include pc {
      width: 39.3rem;
      height: 7rem;
      font-size: 2.4rem;
    }
    @include mobile {
      width: 50%;
      height: 6rem;
      font-size: 2rem;
    }
  }
}

/* AboutUs content style */
/* outer div */
.about-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* common div style */
.about-desc-div {
  @include pc {
    width: 78.6rem;
    margin-top: 5rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
    margin-top: 4rem;
  }
}

/* main title style */
.about-title-div {
  display: flex;
  align-items: center;
  font-weight: 600;
  @include pc {
    img {
      width: 3rem;
      margin-right: 2rem;
    }
    font-size: 3.8rem;
  }
  @include mobile {
    img {
      width: 2.4rem;
      margin-right: 1rem;
    }
    font-size: 3.2rem;
  }
}

/* subtitle style */
.about-subtitle {
  font-weight: 600;
  @include pc {
    font-size: 3.2rem;
  }
  @include mobile {
    font-size: 2.4rem;
  }
}

/* content div style */
.about-content-div {
  li {
    list-style-type: none;
    &::before {
      margin-right: 1rem;
      content: '•';
    }
    @include pc {
      margin-bottom: 2rem;
    }
    @include mobile {
      margin-bottom: 1.5rem;
    }
  }
  @include pc {
    font-size: 2.4rem;
    margin-top: 2rem;
  }
  @include mobile {
    font-size: 2rem;
    margin-top: 1.5rem;
  }
}

.about-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  p:first-child {
    font-weight: 600;
    @include pc {
      margin-bottom: 1rem;
    }
    @include mobile {
      margin-bottom: 0.5rem;
    }
  }
}
