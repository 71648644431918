@import '../abstracts/colors';
@import '../abstracts/mixin';

/* main div for display: flex */
.signup-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Verification style */
.signup-veri-div {
  div:last-child {
    margin-top: 2rem;
    margin-bottom: 25rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
  }
}

/* text style for Verification & Complete Component */
.signup-text {
  font-weight: 500;
  text-align: center;
  @include pc {
    font-size: 3.2rem;
    margin-bottom: 8rem;
    line-height: 1.3;
  }
  @include mobile {
    font-size: 2.4rem;
    margin-bottom: 6rem;
    line-height: 1.4;
  }
}

/* UserType style */
.signup-type-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  font-weight: 700;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
  }
  @include pc {
    width: 50.2rem;
    height: 15rem;
    padding: 0 4rem;
    font-size: 3.2rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
    height: 10rem;
    padding: 0 2.4rem;
    font-size: 2.6rem;
    img {
      width: 8rem;
    }
  }
}

.signup-usertype-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @include pc {
    height: 35rem;
    padding-bottom: 21rem;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
    height: 22rem;
  }
}

/* Form style */
.signup-form-div {
  .inputs-div {
    display: flex;
    flex-direction: column;
  }
  @include mobile {
    width: calc(100% - 4.8rem);
  }
}

/* Terms style */
.terms-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.terms-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light-green;
  padding: 0 2rem;
  border-radius: 1rem;
  cursor: pointer;

  &.terms-title-agreed {
    background-color: $dark-green;
    color: white;
    p,
    div {
      color: white;
    }
  }
  p {
    font-weight: 600;
  }

  @include pc {
    width: 74.6rem;
    height: 7.6rem;

    p {
      font-size: 3.2rem;
    }
  }
  @include mobile {
    width: calc(100% - 4rem);
    height: 6rem;

    p {
      font-size: 2.2rem;
    }
  }

  div {
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      color: $gray;
      @include pc {
        font-size: 2.4rem;
        margin-left: 1.6rem;
      }
      @include mobile {
        font-size: 2rem;
        margin-left: 1rem;
      }
    }
  }
}

.terms-text {
  @include pc {
    width: 76.6rem;
    padding: 1rem;
    margin: 1rem 0;
    p {
      font-size: 2rem;
    }
  }
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 1rem 0;
    p {
      font-size: 1.6rem;
    }
  }
}

.terms-check-div {
  display: flex;
  justify-content: end;
  .checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    p {
      margin-left: 1rem;
    }
  }

  @include pc {
    width: 78.6rem;
    .checkbox:first-child {
      margin-right: 3rem;
    }
    p {
      font-size: 2.4rem;
    }
  }
  @include mobile {
    width: 100%;
    .checkbox:first-child {
      margin-right: 2rem;
    }
    p {
      font-size: 2rem;
    }
  }
}

.square {
  display: flex;
  align-items: center;
  margin: 0;
  @include pc {
    width: 3.6rem;
    height: 3.6rem;
  }
  @include mobile {
    width: 2.4rem;
    height: 2.4rem;
  }
}

/* Complete style */
.signup-comp-div {
  padding-bottom: 36.4rem;
  @include mobile {
    width: calc(100% - 4.8rem);
  }
}
