@import '../abstracts/colors';
@import '../abstracts/mixin';

/* ReviewStar & StarRate styles */
.review-star {
  display: flex;
  img {
    @include mobile {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

.small-star {
  img {
    @include pc {
      width: 2.8rem;
      height: 2.8rem;
    }
    @include mobile {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

/* ReviewList style */
.review-list-div {
  width: 100%;
  .resume-detail-card {
    margin-bottom: 0rem;
  }
  @include pc {
    gap: 3rem;
  }
  @include mobile {
    gap: 2rem;
  }
}

.review-avgstar-div {
  display: flex;
  align-items: center;
  gap: 1rem;
  p {
    font-weight: 500;
    @include pc {
      font-size: 2.8rem;
    }
    @include mobile {
      font-size: 2.4rem;
    }
  }
}

/* ReviewItem style */
.review-item-div {
  width: calc(100% - 4rem);
  padding: 2rem;
  border-radius: 1rem;
}
.review-profile-div {
  display: flex;
}
.review-profile-img {
  border-radius: 100%;
  object-fit: cover;
  flex-shrink: 0;
  overflow: hidden;
  border: 0.1rem solid $light-gray;
  @include pc {
    width: 6rem;
    height: 6rem;
  }
  @include mobile {
    width: 5rem;
    height: 5rem;
  }
}

.review-profile-detail-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  div:last-child {
    display: flex;
    gap: 1rem;
    @include pc {
      font-size: 1.8rem;
    }
    @include mobile {
      font-size: 1.6rem;
    }
    p:first-child {
      font-weight: 500;
    }
    p:last-child {
      color: $dark-gray;
    }
  }
}

.review-info-div {
  display: flex;
  color: $dark-gray;
  margin: 1rem 0;
  @include pc {
    font-size: 1.8rem;
  }
  @include mobile {
    font-size: 1.6rem;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.review-tags-div {
  display: flex;
  gap: 1rem;
  p {
    padding: 0.4rem 0.6rem;
    color: $dark-green;
    background-color: $pale-green;
    border-radius: 0.5rem;
    @include pc {
      font-size: 2rem;
    }
    @include mobile {
      font-size: 1.6rem;
    }
  }
}

.review-content-p {
  @include pc {
    font-size: 2rem;
    margin-top: 2rem;
  }
  @include mobile {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
}

.review-form-div {
  width: 100%;
  @include pc {
    margin-bottom: 5rem;
  }
  @include mobile {
    margin-bottom: 3rem;
  }
  textarea {
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 0.1rem $dark-blue;
    }
  }
}

.review-form-info-div {
  display: flex;
  gap: 0.5rem;
  color: $dark-gray;
  p:first-child {
    font-weight: 600;
  }
  &:first-child {
    margin-bottom: 1rem;
  }
  @include pc {
    font-size: 2.2rem;
  }
  @include mobile {
    font-size: 1.8rem;
  }
}

.review-form-star-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-weight: 600;
    margin-bottom: 1rem;
    @include pc {
      font-size: 2.8rem;
    }
    @include mobile {
      font-size: 2rem;
    }
  }
}

.review-form-notice-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  @include pc {
    align-items: baseline;
    gap: 1rem;
    font-size: 2.4rem;
    margin-bottom: 8rem;
  }
  @include mobile {
    gap: 0.5rem;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 4rem;
  }
}
