.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include pc {
    height: 8rem;
  }
  @include mobile {
    height: 5.6rem;
    border-bottom: 1px solid $light-gray;
  }
}

.header_logo {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: $dark-green;
  cursor: pointer;
  @include mobile {
    font-size: 2.4rem;
    margin-left: 2.4rem;
    gap: 0.5rem;
    img {
      width: 2.4rem;
    }
  }
  @include pc {
    font-size: 3.2rem;
    margin-left: 4rem;
    gap: 0.5rem;
    img {
      width: 3.2rem;
    }
  }
}

.header_txt_container {
  @include main-pc {
    gap: 7.5vw;
  }
  @include main-mobile {
    gap: 3vw;
  }
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.header_txt {
  cursor: pointer;
  font-size: 2.4rem;
  font-weight: 600;
  &:hover {
    color: $dark-green;
  }
}

.header_icon {
  @include pc {
    width: 3.6rem;
  }
  @include mobile {
    width: 2.4rem;
    margin-right: 2rem;
  }
}

.header_btn {
  cursor: pointer;
  border-radius: 1rem;
  border: 1px solid $light-gray;
  color: $dark-green;
  font-size: 2rem;
  font-weight: 600;
  height: 4rem;
  padding: 0rem 1rem;
  margin-right: 4rem;
}

.header-notice {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  @include pc {
    width: 4.5rem;
    height: 6rem;
    margin-right: 3rem;
  }
  @include mobile {
    width: 3.2rem;
    height: 4.5rem;
    margin-right: 2rem;
  }
  img {
    @include mobile {
      margin: 0;
    }
  }
  div {
    position: absolute;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0rem;
    right: 0rem;
    z-index: 1;
    background-color: $red;
    color: $white;
    @include pc {
      min-width: 2.8rem;
      height: 2.8rem;
      font-size: 2.4rem;
    }
    @include mobile {
      min-width: 2rem;
      height: 2rem;
      font-size: 2rem;
    }
  }
}
