.resume-card-container {
  display: flex;
  @include pc {
    flex-wrap: wrap;
    width: 78.6rem;
    gap: 3rem;
    margin-top: 8rem;
  }
  @include mobile {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    margin-top: 4rem;
  }
}

.resume-banner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  @include pc {
    margin-bottom: 8rem;
  }
  @include mobile {
    margin-bottom: 4rem;
  }
}

.resume-input-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.resume-text-area {
  box-shadow: inset 0 0 0 0.1rem $light-gray;
  border-radius: 1rem;
  resize: none;
  margin-top: 1rem;
  @include pc {
    width: calc(100% - 4rem);
    font-size: 2.4rem;
    padding: 2rem;
    height: 16rem;
  }
  @include mobile {
    width: calc(100% - 2rem);
    font-size: 2rem;
    padding: 1rem;
    height: 8rem;
  }
  ::placeholder {
    color: $dark-gray;
  }
}

.add-record-btn {
  @include pc {
    font-size: 2.4rem;
  }
  @include mobile {
    font-size: 2rem;
  }
  font-weight: 600;
  color: $dark-green;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.add-mini-record-btn {
  @extend .add-record-btn;
  position: relative;
  margin: 2rem 0;
}

.info-text {
  @extend .add-record-btn;
  color: $dark-gray;
  cursor: default;
}

.work-type-container {
  @extend .resume-card-container;

  margin-top: 0;
  @include pc {
    gap: 1rem 3rem;
    margin-top: 8rem;
  }
  @include mobile {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 4rem;
  }
}

.work-type-btn {
  @include pc {
    width: 37.8rem;
    height: 6.4rem;
    font-size: 2.4rem;
  }
  @include mobile {
    width: calc(50% - 0.5rem);
    height: 5rem;
    font-size: 2rem;
  }
  border-radius: 1rem;
  font-weight: 600;
}

.slider-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resume-submit-btn {
  @extend .work-type-btn;
  @include pc {
    font-size: 3.2rem;
  }
}

.resume-tab-div {
  @extend .tab-div;
  @include pc {
    .tab-wrapper {
      width: 78.6rem;
      margin-bottom: 8rem;
    }
  }
  @include mobile {
    width: 100%;
    .tab-wrapper {
      margin-bottom: 4rem;
    }
  }
}

.resume-list-empty {
  @extend .mypage-semi-notice;
  height: 30vh;
  font-size: 2rem;
}
