@import '../abstracts/colors';
@import '../abstracts/mixin';

.infoForm-div {
  cursor: default;

  @include pc {
    width: 33rem;
    height: 41.8rem;
    border-radius: 1rem;
    padding: 2.4rem;
    position: relative;
    box-shadow: inset 0 0 0 0.1rem $light-gray;
  }
}

.infoForm-box {
  display: flex;
  @include pc {
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
  @include mobile {
    flex-direction: column;
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
  p:first-child {
    font-weight: 600;
    @include pc {
      width: 8.3rem;
    }
  }
  p:last-child {
    word-wrap: break-word;
    @include pc {
      width: 21.5rem;
    }
    @include mobile {
      width: 100%;
      margin-top: 0.7rem;
    }
  }
}

.infoForm-btn-box {
  display: none;
  @include pc {
    display: flex;
    justify-content: space-between;
    width: 22rem;
    position: absolute;
    bottom: 2.4rem;
    left: 2.4rem;
  }
}
