.search-filter-container {
  background-color: $white;
  padding: 0 2.4rem;
  width: calc(100% - 4.8rem);
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  .reset {
    color: $dark-blue;
    font-weight: 500;
    cursor: pointer;
  }
  @include pc {
    img {
      width: 3.6rem;
      cursor: pointer;
    }
    .reset {
      font-size: 2.4rem;
    }
    top: 9rem;
    width: 78.6rem;
  }
  @include mobile {
    img {
      width: 2.4rem;
    }
    .reset {
      font-size: 2rem;
    }
    top: 5rem;
    width: calc(100% - 4.8rem);
  }
}

.search-filter-contents {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @include pc {
    width: 78.6rem;
  }
  @include mobile {
    width: 100%;
  }
}

.search-filter-confirm-btn {
  @include pc {
    font-size: 2.8rem;
    height: 6.4rem;
    margin-bottom: 8rem;
  }
  @include mobile {
    font-size: 2rem;
    height: 5rem;
    margin-bottom: 4rem;
  }
  width: 100%;
  font-weight: 600;
  background-color: $dark-blue;
  color: $white;
  border-radius: 1rem;
}
