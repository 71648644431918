.filter-container {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  cursor: pointer;
  .img {
    width: 2.4rem;
    height: 2.4rem;
  }
}

.filter-select {
  box-shadow: inset 0 0 0 0.1rem $light-gray;
  border: none;
  border-radius: 1rem;
  font-size: 2rem;
  color: $dark-gray;
  &:focus {
    box-shadow: inset 0 0 0 0.1rem $dark-blue;
  }
}

.search-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include pc {
    margin: 6rem 0 2rem 0;
  }
  @include mobile {
    margin: 4rem 0 1rem 0;
  }
}

.search-select-container {
  @include mobile {
    width: 100%;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
  }
}

.search-info-container {
  display: flex;
  gap: 1rem;
}

.search-title {
  font-weight: 600;
  @include pc {
    font-size: 3.2rem;
  }
  @include mobile {
    font-size: 2.4rem;
  }
}

.search-subtitle {
  @extend .search-title;
  @include pc {
    font-size: 2.8rem;
  }
}

.search-input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .search-delete {
    position: absolute;
    @include pc {
      top: 1.2rem;
      right: 12%;
    }
    @include mobile {
      top: 1.25rem;
      right: 8rem;
      width: 2.4rem;
    }
  }
}

.search-input {
  @include pc {
    height: 6rem;
    font-size: 2.4rem;
    padding: 0 2rem 0 6rem;
    width: 62rem;
  }
  @include mobile {
    height: 5rem;
    background-size: 2.4rem;
    font-size: 2rem;
    padding: 0 2rem 0 5rem;
    width: calc(100% - 14rem);
  }
  box-shadow: inset 0 0 0 0.1rem $light-gray;
  background-color: $pale-blue;
  border-radius: 1rem;
  background: {
    image: url('../../assets/icons/search/search-blue.svg');
    position: 1.5rem center;
    repeat: no-repeat;
  }
  ::placeholder {
    color: $dark-gray;
  }
  &:focus {
    box-shadow: inset 0 0 0 0.1rem $dark-blue;
    background-color: $white;
  }
}

.search-history-container {
  position: absolute;
  top: 5rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: $pale-blue;
  border-radius: 1rem;
  font-size: 2rem;
  font-weight: 500;
  img {
    width: 2.4rem;
  }
  @include pc {
    padding: 2rem;
    width: calc(100% - 4rem);
  }
  @include mobile {
    padding: 1rem;
    width: calc(100% - 2rem);
  }
}

.search-history-title {
  display: flex;
  justify-content: space-between;
  .delete {
    color: $dark-gray;
    font-weight: 400;
  }
  margin-bottom: 2rem;
}
.search-history-contents {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: calc(100% - 3.4rem);
  .content {
    display: flex;
    gap: 1rem;
    font-weight: 400;
    white-space: pre-line;
  }
  .delete {
    position: absolute;
    right: 2rem;
  }
}
