.title {
  text-align: center;
  font-weight: 600;
  @include mobile {
    font-size: 3.2rem;
    margin: 4rem 0rem;
  }
  @include pc {
    font-size: 4.8rem;
    margin: 8rem 0rem;
  }
}
