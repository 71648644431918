@import '../abstracts/mixin';

.main-div {
  display: flex;
  flex-direction: column;
}

.button-div {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  @include main-pc {
    .contents-wrapper {
      display: flex;
      width: 119.4rem;
      justify-content: space-between;
    }
  }
  @include main-mobile {
    .contents-wrapper {
      display: flex;
      width: calc(100% - 4.8rem);
      justify-content: space-between;
    }
  }
  @include mobile {
    margin-top: 2.4rem;
    .contents-wrapper {
      width: calc(100% - 4.8rem);
    }
  }
}

.content-div {
  border-radius: 1rem;
  font-weight: 600;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  @include main-pc {
    width: 37.8rem;
  }
  @include main-mobile {
    width: 30vw;
    img {
      width: 12vw;
    }
  }
  @include mobile {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 13rem;

    width: calc(100% - 4.8rem);
    padding: 0 2.4rem;
    margin-bottom: 2.4rem;
    img {
      width: 10rem;
    }
    .content-mobile-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.content-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  @include main-pc {
    justify-content: center;
    font-size: 4rem;
  }
  @include main-mobile {
    justify-content: center;
    font-size: 3.3vw;
  }
  @include mobile {
    display: flex;
    flex-direction: row;
    font-size: 3.2rem;
  }
}

.content-subtitle {
  @include main-pc {
    font-size: 3rem;
  }
  @include main-mobile {
    font-size: 2.4vw;
  }
  @include mobile {
    display: none;
  }
}

.content-box {
  display: flex;
  align-items: center;
  @include main-pc {
    margin-top: 2rem;
    font-size: 3.2rem;
  }
  @include main-mobile {
    margin-top: 1.5vw;
    font-size: 2.5vw;
  }
  @include mobile {
    margin-top: 1rem;
    font-size: 2rem;
    div {
      display: none;
    }
  }
}
